import { getRegionCode } from "@/lib/locale-helpers";
import { type ParsedPhoneNumber, parsePhoneNumber } from "awesome-phonenumber";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import countries from "../../../../lib/countries.json";
import Input from "../../atoms/Input/Input";
import Text from "../../atoms/Text/Text";
import Button from "../Button/Button";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

interface RequestLoginCodeFormProps {
  formData: {
    name: string;
    phone: string;
  };
  error?: string;
  onFormUpdate: (updates: { [key: string]: string }) => void;
  onSubmit: (name: string, parsedPhone: ParsedPhoneNumber) => Promise<void>;
  onError: (error: string) => void;
}

const nameRegex = /^(?!\s*$)[\p{L}\s'-]+$/u;
export default function RequestLoginCodeForm({
  formData,
  error,
  onFormUpdate,
  onSubmit,
  onError,
}: RequestLoginCodeFormProps) {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [loading, setLoading] = useState(false);
  const [nameValid, setNameValid] = useState(true);
  const parsedPhone = parsePhoneNumber(
    formData.phone,
    formData.phone.startsWith("+") ? undefined : { regionCode: getRegionCode(locale) },
  );
  const [phoneValid, setPhoneValid] = useState(true);

  const validateName = (name?: string) => name && nameRegex.test(name);
  const isValid = nameValid && phoneValid;
  const sendConfirmationCodeButtonDisabled = !isValid || loading;

  const handleChange = (e) => {
    const target = e.target.name;
    const value = e.target.value;

    if (target === "name") {
      const nameValid = validateName(value);
      setNameValid(value);
      if (!nameValid) {
        onError("Please provide a name");
      } else {
        onError(null);
      }
    }
    if (target === "phone") {
      const parsedPhone = parsePhoneNumber(
        value,
        value.startsWith("+") ? undefined : { regionCode: getRegionCode(locale) },
      );
      setPhoneValid(parsedPhone.valid);
      if (!parsedPhone.valid) {
        onError("Please provide a valid phone number");
      } else {
        onError(null);
      }
    }

    onFormUpdate({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid || loading) {
      return;
    }

    setLoading(true);
    await onSubmit(formData.name, parsedPhone);
    setLoading(false);
  };

  return (
    <form className="flex flex-col gap-y-4 w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-y-4">
        <Input
          name="name"
          isValid={nameValid}
          label={t("first_and_last_name")}
          onChange={handleChange}
          value={formData.name}
          autoFocus={true}
          autoComplete="name"
          required
          aria-required="true"
        />
        <Input
          name="phone"
          isValid={phoneValid}
          label={t("mobile_phone_number")}
          required
          onChange={handleChange}
          value={
            parsedPhone.valid
              ? formData.phone.startsWith("+")
                ? parsedPhone.number?.international
                : parsedPhone.number?.national
              : formData.phone
          }
          autoComplete="tel"
          contentRight={
            parsedPhone.valid && parsedPhone.regionCode ? (
              <Text className="text-xl">{countries?.[parsedPhone.regionCode]?.emoji}</Text>
            ) : null
          }
          aria-required="true"
          aria-label={`${t("mobile_phone_number")}, ${countries?.[parsedPhone.regionCode]?.name}`}
        />
      </div>

      <ErrorMessage>{t(error)}</ErrorMessage>

      <div className="w-full sm:px-[20px] pt-8">
        <Button
          variant={sendConfirmationCodeButtonDisabled ? "secondary" : "primary"}
          disabled={sendConfirmationCodeButtonDisabled}
          className="transition-all w-full mx-auto"
          onClick={handleSubmit}
          type="submit"
        >
          {loading ? `${t("sending_code")}...` : t("send_confirmation_code")}
        </Button>
      </div>
    </form>
  );
}
