import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import CookieLoader from "../../atoms/CookieLoader/CookieLoader";
import IconAngleRightSmallRegular from "../../atoms/Icons/AngleRightSmallRegular";
import Text from "../../atoms/Text/Text";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { handleKeyDown } from "@/static/lib/util";
interface ValidateLoginCodeFormProps {
  formData: {
    requestId?: string;
    phone?: string;
    code: string;
  };
  error?: string;
  onFormUpdate?: (updates: { [key: string]: string }) => void;
  onSubmit: (requestId: string, code: string) => Promise<boolean>;
  onBack: () => void;
}

export default function ValidateLoginCodeForm({
  formData,
  error,
  onFormUpdate,
  onSubmit,
  onBack,
}: ValidateLoginCodeFormProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleChange = async (e, index) => {
    const newCode = (formData.code.slice(0, index) + e.target.value).replace(/\D/g, "");
    onFormUpdate({ code: newCode });

    if (newCode.length >= 4) {
      setLoading(true);
      const submissionSuccess = await onSubmit(formData.requestId, newCode);
      onFormUpdate({ code: "" });
      if (!submissionSuccess) {
        setLoading(false);
      }
    } else {
      document.getElementById(`sms-code-${newCode.length}`).focus();
    }
  };

  const handleKeyDownForCode = (e, index) => {
    if (e.key === "Backspace") {
      const newCode = formData.code.slice(0, index - 1);
      onFormUpdate({ code: newCode });
      document.getElementById(`sms-code-${newCode.length}`).focus();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {loading ? (
        <>
          <CookieLoader />
          <div tabIndex={-1} aria-live="assertive" className="sr-only" ref={(el) => el && el.focus()}>
            {t("logging_in")}
          </div>
        </>
      ) : (
        <>
          <div className="space-x-2.5 sm:space-x-4 items-center sm:justify-center flex">
            {Array.from({ length: 4 }).map((_, index) => (
              <input
                key={index}
                id={`sms-code-${index}`}
                name={`sms-code-${index}`}
                type="text"
                inputMode="numeric"
                className={classNames(
                  "w-[60px] h-[60px] sm:w-[78px] sm:h-[78px] border border-solid rounded-full flex-shrink-0",
                  "flex items-center justify-center text-center text-2xl font-bold focus:border-black/10",
                )}
                value={formData.code[index] || ""}
                disabled={loading}
                autoFocus={index === 0}
                onKeyDown={(e) => handleKeyDownForCode(e, index)}
                onChange={(e) => handleChange(e, index)}
                autoComplete="off"
              />
            ))}
          </div>

          <ErrorMessage>{t(error)}</ErrorMessage>
          <Text
            className="mt-7 sm:text-center cursor-pointer"
            onClick={onBack}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => handleKeyDown(e, onBack)}
          >
            {t("didnt_get_a_code_try_again")}
            <IconAngleRightSmallRegular className="inline-block w-5 h-5 -mt-[4px]" />
          </Text>
        </>
      )}
    </div>
  );
}
