import { RedeemReferralDocument } from "@/generated/requests/pos";
import { UpdateMyselfDocument } from "@/generated/requests/services";
import { useCustomerMutation } from "@/lib/apollo-hooks";
import { getRegionCode } from "@/lib/locale-helpers";
import { type ParsedPhoneNumber, parsePhoneNumber } from "awesome-phonenumber";
import classNames from "classnames";
import { usePOSMutation } from "lib/apollo-hooks";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import IconWordmark from "../../atoms/Icons/Wordmark";
import Text from "../../atoms/Text/Text";
import { useCustomerContext } from "../../contexts/CustomerContext/CustomerContext";
import { useRewardsContext } from "../../contexts/RewardsContext/RewardsContext";
import UserTermsAndConditionsAgreement from "../UserTermsAndConditionsAgreement/UserTermsAndConditionsAgreement";
import RequestLoginCodeForm from "./RequestLoginCodeForm";
import ValidateLoginCodeForm from "./ValidateLoginCodeForm";

export interface SignInFormProps {
  onDismiss?: Function;
}

export default function SignInForm({ onDismiss }: SignInFormProps) {
  const { t } = useTranslation();
  const { locale, query } = useRouter();
  const referralCode = query?.referralCode;
  const [redeemReferral] = usePOSMutation(RedeemReferralDocument);
  const [updateMyselfApiCall] = useCustomerMutation(UpdateMyselfDocument);
  const { requestLoginCode, validateLogin } = useCustomerContext();
  const { refetch: refetchRewardsSummary } = useRewardsContext();
  const [formData, setFormData] = useState({ name: "", phone: "", code: "", requestId: "" });
  const [error, setError] = useState<string>();
  const showCodeForm = formData?.requestId;
  const parsedPhone = parsePhoneNumber(
    formData.phone,
    formData.phone.startsWith("+") ? undefined : { regionCode: getRegionCode(locale) },
  );

  const handleDismiss = () => {
    onDismiss?.();
  };

  const handleBackToSignIn = () => {
    setError("");
    setFormData((prev) => ({ ...prev, code: "", requestId: "" }));
  };

  const handleFormUpdate = (updates) => {
    setFormData((prev) => ({ ...prev, ...updates }));
  };

  const handleRequestLoginCode = async (name: string, parsedPhone: ParsedPhoneNumber) => {
    setError(null);
    const { requestId, error } = await requestLoginCode(parsedPhone.number.e164);
    setFormData((prev) => ({ ...prev, requestId }));
    setError(error);
  };

  const handleValidateLoginCode = async (requestId: string, code: string): Promise<boolean> => {
    setError(null);
    const { error } = await validateLogin(requestId, code);

    if (error) {
      setError(error);
      return false;
    }

    try {
      if (formData?.name) {
        await updateMyselfApiCall({ variables: { input: { name: formData.name } } });
      }
    } catch (err) {
      // no-op: not required to continue, let them in still
      console.error("unable to update customer");
    }

    // the backend will determine if the user is new and can redeem the referral code
    try {
      if (referralCode) {
        // @ts-ignore
        await redeemReferral({ variables: { input: { referralCode } } });
      }
    } catch (err) {
      // no-op: not required to continue, let them in still
      // TODO talk to design team, maybe tell user they can't redeem a referral, if they already have made orders
      console.error("unable to redeem referral code");
    }

    try {
      await refetchRewardsSummary();
    } catch (err) {
      // no-op: not required to continue, let them in still
      console.error("unable to retrieve rewards summary");
    }

    handleDismiss();
    return true;
  };

  return (
    <div
      className={classNames(
        "flex flex-col w-full px-[15px] pt-[25px] pb-5 gap-y-[20px]",
        "sm:max-w-[502px] sm:px-[30px] sm:py-[50px] sm:rounded-[20px] lg:gap-y-[50px]",
      )}
    >
      <div className="flex flex-col gap-y-[10px] sm:items-center">
        <Text variant="display3" className="capitalize">
          {showCodeForm ? t("code_sent") : t("sign_in")}
        </Text>
        <Text>
          {showCodeForm
            ? `${t("confirmation_code_sent_to")}: ${
                formData.phone.startsWith("+") ? parsedPhone.number?.international : parsedPhone.number?.national
              }`
            : t("well_text_you_a_confirmation_code_to_get_started")}
        </Text>
      </div>

      <div className="hidden sm:block border-t border-grey-10" />

      <div className="flex flex-col items-center justify-center min-h-[200px] sm:min-h-[]">
        {showCodeForm ? (
          <ValidateLoginCodeForm
            formData={formData}
            onFormUpdate={handleFormUpdate}
            onSubmit={handleValidateLoginCode}
            onBack={handleBackToSignIn}
            error={error}
          />
        ) : (
          <RequestLoginCodeForm
            formData={formData}
            onFormUpdate={handleFormUpdate}
            onSubmit={handleRequestLoginCode}
            error={error}
            onError={(error) => setError(error)}
          />
        )}
      </div>

      <div className="hidden sm:block border-t border-grey-10" />

      <UserTermsAndConditionsAgreement className="text-center" />

      <div className="flex items-center justify-between order-first sm:order-last sm:justify-center">
        <div className="bg-primary py-0.5 px-2 rounded-sm">
          <IconWordmark className="w-24 h-7" />
        </div>
      </div>
    </div>
  );
}
