import SignInForm from "@/components/v2/molecules/SignInForm/SignInForm";
import { useQueryState } from "@/lib/hooks";
import { ImagesV2 } from "@/public/images/all";
import type { GetStaticPropsContext } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";
import { useCustomerContext } from "@/components/v2/contexts/CustomerContext/CustomerContext";
import Head from "next/head";

export default function Login() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const [{ next }] = useQueryState({ next: "/" });
  const { customer } = useCustomerContext();
  const hasQueryParams = searchParams?.size > 0;

  if (customer) {
    router.push("/");
    return null;
  }

  const handleDismiss = () => {
    router.push(next);
  };

  return (
    <>
      {hasQueryParams && (
        <Head>
          <meta name="robots" content="noindex,follow" />
        </Head>
      )}
      <div className="fixed inset-0 z-50 bg-primary">
        <div className="relative w-full h-full max-w-9xl mx-auto">
          <div className="absolute inset-0 hidden sm:flex justify-end items-end lg:items-center">
            <Image
              src={ImagesV2.illustrationSkateboardCookie}
              alt={t("illustration")}
              className="max-h-[503px] object-contain xl:-mr-[12vw]"
            />
          </div>

          <div className="relative w-full h-full flex sm:items-center sm:justify-center lg:justify-start">
            <div className="bg-white sm:rounded-[20px] lg:ml-[12vw] max-h-[100vh]">
              <SignInForm onDismiss={handleDismiss} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const translated = await serverSideTranslations(locale, ["common", "home", "order"]);

  return {
    props: {
      ...translated,
    },
  };
}
