import { Input as HeadlessInput, type InputProps as HeadlessInputProps } from "@headlessui/react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { type ChangeEvent, type FocusEvent, type ReactNode, useEffect, useRef, useState } from "react";
import Text from "../Text/Text";

export interface InputProps extends HeadlessInputProps {
  label?: string;
  textColor?: string;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  validateInput?: boolean;
  inputHeight?: string; //useful for <textarea>
  isValid?: boolean;
}

export default function Input({
  id,
  label,
  textColor,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  className,
  contentLeft,
  contentRight,
  validateInput,
  inputHeight,
  isValid: initialIsValid,
  ...props
}: InputProps) {
  const { t } = useTranslation();
  // allows us to have a controlled or uncontrolled input
  const [valid, setValid] = useState(typeof initialIsValid === "boolean" ? initialIsValid : true);
  const [controlledValue, setControlledValue] = useState("");
  const controlled = typeof value === "undefined";
  const defaultId = id || props.name;
  const isEmpty = !(controlledValue || value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    validateInput && setValid(!isEmpty);
    if (!isEmpty) {
      setValid(true);
    }
  }, [validateInput, isEmpty]);

  useEffect(() => {
    if (typeof initialIsValid === "boolean") {
      setValid(initialIsValid);
    }
  }, [initialIsValid]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setControlledValue(event.target.value);
    onChange?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    props.required && setValid(!isEmpty);
    onBlur?.(event);
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      className={classNames("flex flex-col gap-y-2 w-full relative hover:cursor-text", className)}
      onClick={handleContainerClick}
    >
      <div
        className={classNames(
          inputHeight ? inputHeight : "h-[50px]",
          "relative flex gap-x-2 items-center bg-white",
          "py-1.5 ring-1 ring-inset ring-grey-20 rounded-[10px]",
          "focus-within:ring-2 focus-within:ring-black",
          contentLeft ? "pl-3" : "pl-4",
          contentRight ? "pr-3" : "pr-4",
        )}
      >
        {!valid && <div className="-ml-1 border border-error-red h-4/5 rounded-full" />}
        {contentLeft && <div className="flex-shrink-0">{contentLeft}</div>}
        <div className="relative flex flex-col flex-grow justify-center h-full">
          {label && (
            <motion.label
              htmlFor={defaultId}
              transition={{ duration: 0.1 }}
              initial={isEmpty ? "large" : "small"}
              animate={isEmpty ? "large" : "small"}
              variants={{
                small: {
                  top: "0.125rem",
                  fontSize: "0.75rem",
                  lineHeight: "0.875rem",
                  fontWeight: 700,
                },
                large: {
                  top: ".5625rem",
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                  fontWeight: 400,
                },
              }}
              className={classNames("absolute transition-colors", isEmpty ? "text-black/60" : "text-black/40")}
            >
              {label}
            </motion.label>
          )}
          <HeadlessInput
            ref={inputRef}
            id={defaultId}
            placeholder={label ? undefined : placeholder}
            value={controlled ? controlledValue : value}
            onChange={controlled ? handleChange : onChange}
            onBlur={handleBlur}
            className={classNames(
              "w-full border-0 p-0 text-black leading-5 placeholder:text-black/60 focus:ring-0 bg-transparent",
              label && !isEmpty && "pt-5 pb-1",
              isEmpty && "z-[1]",
              inputHeight,
              textColor && textColor,
            )}
            {...props}
          />
        </div>
        {contentRight && <div>{contentRight}</div>}
      </div>
      {!valid && (
        <div>
          <Text variant="finePrint" className="absolute top-14">
            * {t("this_field_is_required")}
          </Text>
        </div>
      )}
    </div>
  );
}
