import IconExclamationCircleFilled from "../../atoms/Icons/ExclamationCircleFilled";
import Text from "../../atoms/Text/Text";

interface ErrorMessageProps {
  children?: string;
}

export default function ErrorMessage({ children }: ErrorMessageProps) {
  if (!children) {
    return null;
  }

  return (
    <Text variant="caption" className="mt-4 inline-flex items-center gap-1">
      <IconExclamationCircleFilled className="w-[20px] h-[20px] text-[#D95654] flex-shrink-0 mt-px" />
      {children}
    </Text>
  );
}
